import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../../components/Layout'

function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
    this.state = { checked: false };
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      status: ""
    };
  }

  handleChange = (e) => {
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
  }

  render() {
    const { status } = this.state;

    return (
        <Layout>
          <section className="section">
            <div className="container">
              <div className="content">
                <h1>Kundeendring</h1>
                <form
                    onSubmit={this.submitForm}
                    action="https://formspree.io/f/mdopdpyg"
                    method="POST"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="kundeendring" />

                  <div className="field">
                    <label >Skal anlegget kobles fra:
                      <input style = {{ marginleft : 20 }}
                          name="oppsetting"
                          type="checkbox"
                          checked={this.state.OPPSETTING}
                          onChange={this.handleInputChange} /> JA
                    </label>
                    <label style = {{ marginLeft : 20 }}>
                      <input
                          name="nedtaking"
                          type="checkbox"
                          checked={this.state.NEDTAKING}
                          onChange={this.handleInputChange} /> NEI, fyll ut ny kunde
                    </label>
                  </div>
                  <br></br>
                  <div className="col-50">
                    <h3>Anleggsbeskrivelse</h3>
                    <fieldset>
                      <div className="row" >
                        <div className="col-50">
                          <div className="row">
                            <div className="col-50">
                              <label htmlFor="state">Anleggsnummer/målepunkt</label>
                              <input className="input" type={'text'} name={'målepunkt:gnr'} onChange={this.handleChange} id={'gnr'}
                                     required={true}/>
                            </div>
                            <div className="col-50">
                              <label htmlFor="zip">Målenummer</label>
                              <input className="input" type={'text'} name={'målepunkt:målenummer'} onChange={this.handleChange} id={'bnr'} />
                            </div>
                            <div className="col-50">
                              <label htmlFor="fnr">Målestand: (må fylles ut) </label>
                              <input className="input" type={'text'} name={'målepunkt:målestand'} onChange={this.handleChange} id={'fnr'}
                                     required={true}/>
                            </div>
                            <div className="col-75">
                              <label htmlFor="beskrivelse">Dato for overtagelse</label>
                              <input className="input" type={'text'} name={'målepunkt:dato'} onChange={this.handleChange} id={'beskrivelse'}
                                     required={true}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <br></br>

                  <div className="col-50">
                    <h3>Opphør kunde</h3>
                    <fieldset>
                      <div className="row" >
                        <div className="col-50">
                          <div className="row">
                            <div className="row">
                              <div className="col-50">
                                <label htmlFor="etternavn">Etternavn/firmanavn</label>
                                <input className="input" type={'text'} name={'kunde:etternavn-firma'} onChange={this.handleChange} id={'etternavn'} required={true}/>
                              </div>
                              <div className="col-50">
                                <label htmlFor="fornavn">Fornavn/kontaktperson</label>
                                <input className="input" type={'text'} name={'kunde:fornavn-kontakt'} onChange={this.handleChange} id={'fornavn'} required={true}/>
                              </div>
                            </div>
                            <div className="col-50">
                              <label htmlFor="kunenummer">Kundenummer</label>
                              <input className="input" type={'text'} name={'kunde:kunenummer'} onChange={this.handleChange} id={'kunenummer'} required={true}/>
                            </div>
                          </div>


                          <div className="row">
                            <div className="col-50">
                              <label htmlFor="adresse">Gate/vei/regningsadresse</label>
                              <input className="input" type={'text'} name={'Kunde:adresse'} onChange={this.handleChange} id={'adresse'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="postnummer">Postnummer</label>
                              <input className="input" type={'text'} name={'Kunde:postnummer'} onChange={this.handleChange} id={'postnummer'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="poststed">Poststed</label>
                              <input className="input" type={'text'} name={'Kunde:poststed'} onChange={this.handleChange} id={'poststed'} required={true}/>
                            </div>
                          </div>


                          <div className="row">
                            <div className="col-15">
                              <label htmlFor="dato">Dato</label>
                              <input className="input" type={'text'} name={'bestiller:dato'} onChange={this.handleChange} id={'dato'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="sted">Sted</label>
                              <input className="input" type={'text'} name={'bestiller:sted'} onChange={this.handleChange} id={'sted'} required={true}/>
                            </div>
                            <div className="col-50">
                              <label htmlFor="navn">Navn</label>
                              <input className="input" type={'text'} name={'bestiller:navn'} onChange={this.handleChange} id={'navn'} required={true}/>
                            </div>
                          </div>


                          <div className="row">
                            <div className="col-75">
                              <label htmlFor="state">Underskrift</label>
                              <input className="input" type={'text'} name={'bestiller:underskrift'} onChange={this.handleChange} id={'underskrift'} />
                            </div>
                          </div>

                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <h3>Kunde</h3>

                  <div className="col-50 hidden">

                    <fieldset>

                      <div className="row" >
                        <div className="col-50">
                          <div className="row">
                            <div className="col-50">
                              <label htmlFor="state">Etternavn/firmanavn</label>
                              <input className="input" type={'text'} name={'Kunde:gnr'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-50">
                              <label htmlFor="zip">Fornavn/kontaktperson</label>
                              <input className="input" type={'text'} name={'Kunde:bnr'} onChange={this.handleChange} id={'bnr'} required={true}/>
                            </div>
                          </div>


                          <div className="row">
                            <div className="col-50">
                              <label htmlFor="state">Gate/vei/regningsadresse</label>
                              <input className="input" type={'text'} name={'Kunde:adresse'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="state">Postnummer</label>
                              <input className="input" type={'text'} name={'Kunde:postnummer'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="state">Poststed</label>
                              <input className="input" type={'text'} name={'Kunde:poststed'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-15">
                              <label htmlFor="state">Telefon/mobiltelefon</label>
                              <input className="input" type={'text'} name={'Kunde:telefon'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="state">E-post</label>
                              <input className="input" type={'text'} name={'Kunde:epost'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-50">
                              <label htmlFor="state">Fødelsdato/organisasjonsnummer</label>
                              <input className="input" type={'text'} name={'Kunde:fnr'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                          </div>


                          <div className="col-75">
                            <label htmlFor="state">Nåværende kraftleverandør</label>
                            <input className="input" type={'text'} name={'Kunde:opplysninger'} onChange={this.handleChange} id={'gnr'}/>
                          </div>
                          <div className="row">
                            <div className="col-15">
                              <label htmlFor="state">Dato</label>
                              <input className="input" type={'text'} name={'Kunde:dato'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-15">
                              <label htmlFor="state">Sted</label>
                              <input className="input" type={'text'} name={'Kunde:sted'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                            <div className="col-50">
                              <label htmlFor="state">Navn</label>
                              <input className="input" type={'text'} name={'Kunde:navn'} onChange={this.handleChange} id={'gnr'} required={true}/>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-75">
                              <label htmlFor="state">Underskrift</label>
                              <input className="input" type={'text'} name={'Kunde:gnr'} onChange={this.handleChange} id={'gnr'} />
                            </div>
                          </div>
                          <br/>
                          <br/>
                          <div className="row">
                            <div className="col-75">
                              <label htmlFor="state">For nettleieleverandøren</label>
                              <input className="input" type={'text'} name={'Kunde:gnr'} onChange={this.handleChange} id={'gnr'} />
                            </div>
                          </div>

                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <br/>
                  <br/>


                  <br/>

                  <div>
                  </div>
                  <div className="field">
                    {status === "SUCCESS" ? <p>Takk for innsendingen!</p> : <button className="button is-link">Send</button>}
                    {status === "ERROR" && <p>Ooops! There was an error.</p>}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </Layout>
    )
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

